import 'core-js';
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import 'react-app-polyfill/ie11';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { UserManager } from 'oidc-client';

declare global {
  interface Window {
    STAFF_SYSTEM_INFO?: server.SystemInfoM;
    STAFF_LAYOUT?: server.UiNavigationM;
    AUTH_SERVICE?: UserManager;
    AUTH_INFO?: server.AuthInfoM;
  }
}

if (process.env.REACT_APP_ENABLE_TESTING === 'true') {
  import('./e2e').then(() => {
    import('./app').then(({ renderApp }) => renderApp());
  });
} else {
  import('./app').then(({ renderApp }) => renderApp());
}
